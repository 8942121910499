<template>
  <div class="main_container">
    <div class="row mb-3 bread-crumbs">
      <div class="text-left col-12">
        <router-link :to="'/Marche'">
          {{user[1]!="DGIR"?"Marchés":"Lettres de commande"}} >
        </router-link>
        <span>{{user[1]!="DGIR"?"Création de marché":"Création de lettre de commande"}}</span>
      </div>
    </div>
    <div class="row justify-content-center mb-4">
      <div class="col-8">
        <div class="card title-card">
          <div class="card-body text-center title">
            {{user[1]!="DGIR"?"Création de marché":"Création de lettre de commande"}}
          </div>
        </div>
      </div>
    </div>
    <div class="col-md-12 mx-auto">
      <div class="row">
        <Notif :notif="notif" />
      </div>
      <form class="accordion" 
            id="accordionExample">
        <table class="table col-md-8 mx-auto"
               style="font-size: 0.6em;">
          <thead class="card-header"
                 id="headingOne">
            <tr class="bg-black"
                data-toggle="collapse"
                data-target="#collapseOne"
                aria-expanded="true"
                aria-controls="collapseOne">
              <th>Source {{user[1]!="DGIR"?"du marché":"de la lettre de commande"}}</th>
            </tr>
          </thead>
          <div id="collapseOne"
               class="collapse show"
               aria-labelledby="headingOne"
               data-parent="#accordionExample">
            <div class="mt-3">
              <div class="form-row row">
                <div class="col-md-4 form-group">
                  <h6>
                    <label>
                      Catégorie du programme <sup><span class="req-star">*</span></sup>
                    </label>
                  </h6>
                </div>
                <div class="col-md-4 form-group"
                     v-if="user[1]=='FER'">
                  <v-select :options="[{value:'programme_entretien_routier',libelle:'Programme d\'Entretien Routier'},{value:'programme_investissement',libelle:'Programme d\'investissement'}]"
                            :reduce="categorie => categorie.value"
                            label="libelle"
                            ref="categorie_programme"
                            id="categorie_programme"
                            v-model="form.categorie_programme"
                            @input="selectProgramme"
                            placeholder="Choisir une catégorie"
                  >
                    <span v-if="form.errors().has('programme') && affiche.programme !== 'programme'"
                          v-text="form.errors().get('programme')"
                          class="errorMsg"> </span>
                  </v-select>
                </div>
                <div class="col-md-4 form-group"
                     v-else>
                  <v-select :options="[{value:'programme_entretien_routier',libelle:'Programme d\'Entretien Routier'}]"
                            :reduce="categorie => categorie.value"
                            label="libelle"
                            ref="categorie_programme"
                            id="categorie_programme"
                            v-model="form.categorie_programme"
                            @input="selectProgramme"
                            placeholder="Choisir une catégorie"
                  >
                    <span v-if="form.errors().has('programme') && affiche.programme !== 'programme'"
                          v-text="form.errors().get('programme')"
                          class="errorMsg"> </span>
                  </v-select>
                </div>
              </div>
              <div class="form-row row">
                <div class="col-md-4 form-group">
                  <h6>
                    <label>
                      Programme <sup><span class="req-star">*</span></sup>
                    </label>
                  </h6>
                </div>
                <div class="col-md-4 form-group">
                  <v-select :options="programmes.donnees"
                            :reduce="programme => programme.id"
                            label="nom_programme"
                            ref="programme"
                            id="programme"
                            v-model="form.programme"
                            @input="getProgrameIdSelected"
                            placeholder="Choisir un programme"
                  >
                    <span slot="no-options"
                          @click="$refs.programme.open = false"
                          class="text-danger">
                      Aucun Programme
                    </span>
                  </v-select>
                  <span v-if="form.errors().has('programme') && affiche.programme !== 'programme'"
                        v-text="form.errors().get('programme')"
                        class="errorMsg"> </span>
                </div>
              </div>
              <div class="form-row row">
                <div class="col-md-4 form-group">
                  <h6>
                    <label>
                      Rubrique <sup><span class="req-star">*</span></sup>
                    </label>
                  </h6>
                </div>
                <div class="col-md-4 form-group">     
                  <v-select :options="rubriquesList"
                            :reduce="rubrique => rubrique.id"
                            label="libelle"
                            ref="rubrique"
                            v-model="form.rubrique"
                            @input="getRubriqueIdSelected"
                            placeholder="Choisir une rubrique">
                    <span slot="no-options"
                          @click="$refs.rubrique.open = false"
                          class="text-danger">
                      Aucune Rubrique
                    </span>
                  </v-select>
                  <span v-if="form.errors().has('rubrique') && affiche.rubrique !== 'rubrique'"
                        v-text="form.errors().get('rubrique')"
                        class="errorMsg"> </span>
                </div>
              </div>
              <div class="form-row row">
                <div class="col-md-4 form-group">
                  <h6>
                    <label>
                      Activité <sup><span class="req-star">*</span></sup>
                    </label>
                  </h6>
                </div>
                <div class="col-md-4 form-group">
                  <v-select
                    :options="activitesList"
                    :reduce="activite => activite.id"
                    label="libelle"
                    ref="activite"
                    v-model="form.activite"
                    @input="removeSPan('activite'),getMontantActivite(form.activite)"
                    placeholder="Choisir une activité"
                  >
                    <span slot="no-options"
                          @click="$refs.activite.open = false"
                          class="text-danger">
                      Aucune Activité
                    </span>
                  </v-select>
                  <span v-if="form.errors().has('activite') && affiche.activite !== 'activite'"
                        v-text="form.errors().get('activite')"
                        class="errorMsg"> </span>
                </div>
              </div>
              <div class="form-row row">
                <div class="col-md-4 form-group">
                  <h6>
                    <label
                    >Montant définitif activité <sup><span class="req-star">*</span></sup>
                    </label>
                  </h6>
                </div>
                <div class="col-md-4 form-group">
                  <input type="text"
                         disabled
                         class="form-control"
                         v-model="montant_definitif_activite" />
                </div>
              </div>
            </div>
          </div> 
        </table>
        <table class="table col-md-8 mx-auto"
               style="font-size: 0.6em;">
          <thead class="card-header"
                 id="headingTwo">
            <tr class="bg-black"
                data-toggle="collapse"
                data-target="#collapseTwo"
                aria-expanded="false"
                aria-controls="collapseTwo">
              <th>Informations {{user[1]!="DGIR"?"du marché":"de la lettre de commande"}}</th>
            </tr>
          </thead>
          <div id="collapseTwo"
               class="collapse"
               aria-labelledby="headingTwo"
               data-parent="#accordionExample">
            <div class="mt-3">
              <div class="form-row row">
                <div class="col-md-4 form-group">
                  <h6>
                    <label
                    >Montant initial {{user[1]!="DGIR"?"du marché":"de la lettre de commande"}} <sup><span class="req-star">*</span></sup>
                    </label>
                  </h6>
                </div>
                <div class="col-md-4 form-group">
                  <input type="text"
                         @input="addSpace('montant_initial_marche')"
                         @change="checkMontantActivite(form.activite)"
                         class="form-control"
                         v-on:click="removeSPan('cout_marche')"
                         v-model="montant_initial_marche" />
                  <span v-if="form.errors().has('cout_marche') && affiche.cout_marche !== 'cout_marche'"
                        v-text="form.errors().get('cout_marche')"
                        class="errorMsg"> </span>
                </div>
              </div>
              <div class="form-row row">
                <div class="col-md-4 form-group">
                  <h6>
                    <label>Domiciliation Bancaire:<sup><span class="req-star">*</span></sup></label>
                  </h6>
                </div>
                <div class="col-md-4 form-group">
                  <input type="text"
                         class="form-control"
                         name="" 
                         id=""
                         v-model="form.domiciliation">
                </div>
              </div>
              <div class="form-row row">
                <div class="col-md-4 form-group">
                  <h6>
                    <label
                    >Références Ordre de service d'arrêt
                    </label>
                  </h6>
                </div>
                <div class="col-md-4 form-group">
                  <input type="text"
                         class="form-control"
                         v-model="form.reference_ordre_service_arret"/>
                </div>
              </div>
              <div class="form-row row">
                <div class="col-md-4 form-group">
                  <h6><label for="">Date de l'Ordre de service d'arrêt</label></h6>
                </div>
                <div class="col-md-4 form-group">
                  <input type="date"
                         class="form-control" 
                         name="" 
                         id="" 
                         v-model="form.date_ordre_service_arret">
                </div>
              </div>
              <div class="form-row row">
                <div class="col-md-4 form-group">
                  <h6>
                    <label>
                      Références Ordre de service de reprise des travaux
                    </label>
                  </h6>
                </div>
                <div class="col-md-4 form-group">
                  <input type="text"
                         class="form-control"
                         v-model="form.reference_ordre_service_reprise_travaux"/>
                </div>
              </div>
              <div class="form-row row">
                <div class="col-md-4 form-group">
                  <h6>
                    <label for="">Date de reprise des travaux selon l'ordre de service </label>
                  </h6>
                </div>
                <div class="col-md-4 form-group">
                  <input type="date"
                         class="form-control" 
                         name="" 
                         id="" 
                         v-model="form.date_reprise_travaux_selon_ordre_service">
                </div>
              </div>
              <div class="form-row row">
                <div class="col-md-4 form-group">
                  <h6>
                    <label for="">Date d'établissement du cautionnement AD</label>
                  </h6>
                </div>
                <div class="col-md-4 form-group">
                  <input type="date"
                         class="form-control" 
                         name="" 
                         id="" 
                         v-model="form.expiration_caution_ad">
                </div>
              </div>
              <div class="form-row row">
                <div class="col-md-4 form-group">
                  <h6>
                    <label for="">Date expiration de la caution AD</label>
                  </h6>
                </div>
                <div class="col-md-4 form-group">
                  <v-select
                    :options="[
                      {libelle:'Date',valeur:'date'},
                      {libelle:'Remboursement intégrale de l\'avance démarrage',valeur:'Remboursement intégrale de l\'avance démarrage'},
                      {libelle:'Reception provisoire des travaux',valeur:'Reception provisoire des travaux'},
                      {libelle:'Reception définitif des travaux',valeur:'Reception définitif des travaux'}]"
                    :reduce="expiration => expiration.valeur"
                    label="libelle"
                    ref="expiration"
                    id="expiration"
                    @input="checkExpiration('expiration_ad','expiration_caution_ad_texte')"
                    v-model="expiration_ad"
                    placeholder="Choisir une expiration"
                  ></v-select>
                </div>
                <div class="col-md-4 form-group">
                  <input type="date"
                         class="form-control" 
                         name="" 
                         id=""
                         v-if="expiration_ad=='date'" 
                         v-model="form.expiration_caution_ad_date">
                </div>
              </div>
              <div class="form-row row">
                <div class="col-md-4 form-group">
                  <h6>
                    <label for="">Montant de l'Avance de démarrage</label>
                  </h6>
                </div>
                <div class="col-md-4 form-group">
                  <input type="text"
                         class="form-control" 
                         name="" 
                         id="" 
                         @input="addSpace('montant_caution_ad')"
                         v-model="montant_caution_ad">
                </div>
              </div>
              <div class="form-row row">
                <div class="col-md-4 form-group">
                  <h6>
                    <label for="">Date d'établissement du cautionnement RG</label>
                  </h6>
                </div>
                <div class="col-md-4 form-group">
                  <input type="date"
                         class="form-control" 
                         name="" 
                         id="" 
                         v-model="form.date_etablissement_cautionnement_rg">
                </div>
              </div>
              <div class="form-row row">
                <div class="col-md-4 form-group">
                  <h6>
                    <label for="">Date expiration de la caution RG</label>
                  </h6>
                </div>
                <div class="col-md-4 form-group">
                  <v-select
                    :options="[
                      {libelle:'Date',valeur:'date'},
                      {libelle:'Remboursement intégrale de l\'avance démarrage',valeur:'Remboursement intégrale de l\'avance démarrage'},
                      {libelle:'Reception provisoire des travaux',valeur:'Reception provisoire des travaux'},
                      {libelle:'Reception définitif des travaux',valeur:'Reception définitif des travaux'}]"
                    :reduce="expiration => expiration.valeur"
                    label="libelle"
                    ref="expiration"
                    id="expiration"
                    @input="checkExpiration('expiration_rg','expiration_caution_rg_texte')"
                    v-model="expiration_rg"
                    placeholder="Choisir une expiration"
                  ></v-select>
                </div>
                <div class="col-md-4 form-group">
                  <input type="date"
                         class="form-control" 
                         name="" 
                         id=""
                         v-if="expiration_rg=='date'" 
                         v-model="form.expiration_caution_rg_date">
                </div>
              </div>
              <div class="form-row row">
                <div class="col-md-4 form-group">
                  <h6>
                    <label for="">Montant de la retenue garantie</label>
                  </h6>
                </div>
                <div class="col-md-4 form-group">
                  <input type="text"
                         class="form-control" 
                         name="" 
                         id="" 
                         @input="addSpace('montant_caution_rg')"
                         v-model="montant_caution_rg">
                </div>
              </div>
              <div class="form-row row">
                <div class="col-md-4 form-group">
                  <h6>
                    <label for="">Date d'établissement du cautionnement définitif</label>
                  </h6>
                </div>
                <div class="col-md-4 form-group">
                  <input type="date"
                         class="form-control" 
                         name="" 
                         id="" 
                         v-model="form.date_etablissement_cautionnement_definitif">
                </div>
              </div>
              <div class="form-row row">
                <div class="col-md-4 form-group">
                  <h6>
                    <label for="">Date d'expiration du cautionnement définitif</label>
                  </h6>
                </div>
                <div class="col-md-4 form-group">
                  <v-select
                    :options="[
                      {libelle:'Date',valeur:'date'},
                      {libelle:'Remboursement intégrale de l\'avance démarrage',valeur:'Remboursement intégrale de l\'avance démarrage'},
                      {libelle:'Reception provisoire des travaux',valeur:'Reception provisoire des travaux'},
                      {libelle:'Reception définitif des travaux',valeur:'Reception définitif des travaux'}]"
                    :reduce="expiration => expiration.valeur"
                    label="libelle"
                    ref="expiration"
                    id="expiration"
                    @input="checkExpiration('expiration_definitif','expiration_caution_definitif_texte')"
                    v-model="expiration_definitif"
                    placeholder="Choisir une expiration "
                  ></v-select>
                </div>
                <div class="col-md-4 form-group">
                  <input type="date"
                         class="form-control" 
                         name="" 
                         id=""
                         v-if="expiration_definitif=='date'"
                         v-model="form.expiration_caution_definitif_date">
                </div>
              </div>
              <div class="form-row row">
                <div class="col-md-4 form-group">
                  <h6>
                    <label for="">Montant du cautionnement definitif</label>
                  </h6>
                </div>
                <div class="col-md-4 form-group">
                  <input type="text"
                         class="form-control" 
                         name="" 
                         id="" 
                         @input="addSpace('montant_caution_definitif')"
                         v-model="montant_caution_definitif">
                </div>
              </div>
              <div class="form-row row">
                <div class="col-md-4 form-group">
                  <h6>
                    <label for="">Date de fin des travaux</label>
                  </h6>
                </div>
                <div class="col-md-4 form-group">
                  <input type="date"
                         class="form-control" 
                         name="" 
                         id="" 
                         v-model="form.date_fin_travaux">
                </div>
              </div>
              <div class="form-row row">
                <div class="col-md-4 form-group">
                  <h6>
                    <label>
                      Maitrise d'ouvrage<sup><span class="req-star">*</span></sup>
                    </label>
                  </h6>
                </div>
                <div class="col-md-4 form-group">
                  <input type="text" 
                         class="form-control"
                         v-model="form.maitrise_ouvrage_deleguee">
                  <span v-if="form.errors().has('maitrise_ouvrage_deleguee') && affiche.maitrise_ouvrage_deleguee !== 'maitrise_ouvrage_deleguee'"
                        v-text="form.errors().get('maitrise_ouvrage_deleguee')"
                        class="errorMsg"> </span>
                </div>
              </div>
              <div class="form-row row">
                <div class="col-md-4 form-group">
                  <h6>
                    <label>
                      Maitrise d'ouvrage déléguée<sup><span class="req-star">*</span></sup>
                    </label>
                  </h6>
                </div>
                <div class="col-md-4 form-group">
                  <v-select
                    v-if="user[1]=='FER'"
                    :options="prestataires.donnees"
                    :reduce="programme => programme.id"
                    label="libelle"
                    ref="maitre_oeuvre"
                    v-model="form.maitre_oeuvre"
                    @input="removeSPan('maitre_oeuvre')"
                    placeholder="Choisir une maitrise d'ouvrage déléguée"
                  >
                    <span slot="no-options"
                          @click="$refs.maitre_oeuvre.open = false"
                          class="text-danger">
                      Aucune maitrise d'ouvrage déléguée
                    </span>
                  </v-select>
                  <input type="text"
                         class="form-control"
                         disabled
                         :placeholder="user[1]"
                         v-else>
                  <span v-if="form.errors().has('maitre_oeuvre') && affiche.maitre_oeuvre !== 'maitre_oeuvre'"
                        v-text="form.errors().get('maitre_oeuvre')"
                        class="errorMsg"> </span>
                </div>
              </div>  
              <div class="form-row row">
                <div class="col-md-4 form-group">
                  <h6>
                    <label>
                      Libellé <sup><span class="req-star">*</span></sup>
                    </label>
                  </h6>
                </div>
                <div class="col-md-4 form-group">
                  <input type="text"
                         class="form-control"
                         v-on:click="removeSPan('libelle')"
                         v-model="form.libelle" />
                  <span v-if="form.errors().has('libelle') && affiche.libelle !== 'libelle'"
                        v-text="form.errors().get('libelle')"
                        class="errorMsg"> </span>
                </div>
              </div>
              <div class="form-row row">
                <div class="col-md-4 form-group">
                  <h6>
                    <label>
                      Numéro de marché/lettre de commande <sup><span class="req-star">*</span></sup>
                    </label>
                  </h6>
                </div>
                <div class="col-md-4 form-group">
                  <input type="text"
                         name="numMarch"
                         class="form-control"
                         v-on:click="removeSPan('numero_marche')"
                         v-model="form.numero_marche" />
                  <span v-if="form.errors().has('numero_marche') && affiche.numero_marche !== 'numero_marche'"
                        v-text="form.errors().get('numero_marche')"
                        class="errorMsg"> </span>
                </div>
              </div>
              <div class="form-row row">
                <div class="col-md-4 form-group">
                  <h6>
                    <label>
                      Mission de suivi de contrôle des travaux
                    </label>
                  </h6>
                </div>
                <div class="col-md-4 form-group">
                  <v-select
                    :options="missionControls.donnees"
                    :reduce="missionControl => missionControl.id"
                    label="libelle"
                    ref="mission"
                    v-model="form.mission_suivi_controle"
                    @input="removeSPan('mission')"
                    placeholder="Choisir une mission de contrôle"
                  >
                    <span slot="no-options"
                          @click="$refs.mission.open = false"
                          class="text-danger">
                      Aucune mission de contrôle
                    </span>
                  </v-select>
                </div>
              </div>
              <div class="form-row row">
                <div class="col-md-4 form-group">
                  <h6>
                    <label>
                      Date début <sup><span class="req-star">*</span></sup>
                    </label>
                  </h6>
                </div>
                <div class="col-md-4 form-group">
                  <input type="date"
                         v-model="form.date_debut"
                         :ref="'date_debut'"
                         @change="monthDiff()"
                         v-on:click="removeSPan('date_debut')"
                         v-on:change="getDate()"
                         class="form-control" />
                  <span v-if="form.errors().has('date_debut') && affiche.date_debut !== 'date_debut'"
                        v-text="form.errors().get('date_debut')"
                        class="errorMsg"> </span>
                </div>
              </div>
              <div class="form-row row">
                <div class="col-md-4 form-group">
                  <h6>
                    <label>
                      Date fin <sup><span class="req-star">*</span></sup>
                    </label>
                  </h6>
                </div>
                <div class="col-md-4 form-group">
                  <input type="date"
                         v-model="form.date_fin"
                         :ref="'date_fin'"
                         @change="monthDiff()"
                         v-on:click="removeSPan('date_fin')"
                         v-on:change="getDate()"
                         class="form-control" />
                  <span v-if="form.errors().has('date_fin') && affiche.date_fin !== 'date_fin'"
                        v-text="form.errors().get('date_fin')"
                        class="errorMsg"> </span>
                </div>
              </div>
              <div class="form-row row">
                <div class="col-md-4 form-group">
                  <h6>
                    <label>
                      Délai d'exécution {{user[1]!="DGIR"?"du marché":"de la lettre de commande"}} (mois)<sup><span class="req-star">*</span></sup>
                    </label>
                  </h6>
                </div>
                <div class="col-md-4 form-group">
                  <input type="number"
                         v-model="form.delai_execution_marche"
                         :ref="'delai_execution_marche'"
                         v-on:click="removeSPan('delai_execution_marche')"
                         v-on:change="getDate()"
                         class="form-control" 
                         disabled/>
                  <span v-if="form.errors().has('delai_execution_marche') && affiche.delai_execution_marche !== 'delai_execution_marche'"
                        v-text="form.errors().get('delai_execution_marche')"
                        class="errorMsg"> </span>
                </div>
              </div>
              <div class="form-row row">
                <div class="col-md-4 form-group">
                  <h6> 
                    <label>
                      Département <sup><span class="req-star">*</span></sup>
                    </label>
                  </h6>
                </div>
                <div class="col-md-4 form-group">
                  <v-select
                    :options="departements.donnees"
                    :reduce="departement => departement.id"
                    label="libelle"
                    ref="departement"
                    id="departement"
                    v-model="form.departement"
                    multiple
                    placeholder="Choisir un département"
                  >
                    <span slot="no-options"
                          @click="$refs.programme.open = false"
                          class="text-danger">
                      Aucun Département
                    </span>
                  </v-select>
                  <span v-if="form.errors().has('delai_execution_marche') && affiche.delai_execution_marche !== 'delai_execution_marche'"
                        v-text="form.errors().get('delai_execution_marche')"
                        class="errorMsg"> </span>
                </div>
              </div>
              <div class="form-row row">
                <div class="col-md-4 form-group">
                  <h6>
                    <label>
                      Nature du réseau <sup><span class="req-star">*</span></sup>
                    </label>
                  </h6>
                </div>
                <div class="col-md-4 form-group">
                  <v-select :options="[{value:'TERRE',libelle:'Route en terre'},{value:'REVETUE',libelle:'Route revêtue'},{value:'AUTRE',libelle:'Autre'}]"
                            :reduce="nature => nature.id"
                            label="libelle"
                            ref="nature_reseau"
                            id="nature_reseau"
                            v-model="form.nature_reseau"
                            placeholder="Choisir une nature">

                    <span v-if="form.errors().has('departement') && affiche.departement !== 'departement'"
                          v-text="form.errors().get('departement')"
                          class="errorMsg"> </span>
                  </v-select>
                </div>
              </div>
              <div class="form-row row">
                <div class="col-md-4 form-group">
                  <h6>
                    <label>
                      Type d'entretien <sup><span class="req-star">*</span></sup>
                    </label>
                  </h6>
                </div>
                <div class="col-md-4 form-group">
                  <v-select :options="[
                              {value:'PERIODIQUE',libelle:'Période'},
                              {value:'COURANT',libelle:'Courant'},
                              {value:'REHABILITATION',libelle:'Réhabilitation'},
                              {value:'TRAVAUX_NOEUFS',libelle:'Travaux neufs'},
                              {value:'AUTRE',libelle:'Autre'}]"
                            :reduce="type_entretien => type_entretien.id"
                            label="libelle"
                            ref="type_entretien"
                            id="type_entretien"
                            v-model="form.type_entretien"
                            placeholder="Choisir un type d'entretien">

                    <span v-if="form.errors().has('departement') && affiche.departement !== 'departement'"
                          v-text="form.errors().get('departement')"
                          class="errorMsg"> </span> 
                  </v-select> 
                </div>
              </div>
              <div class="form-row row">
                <div class="col-md-4 form-group">
                  <h6>
                    <label>
                      Lot<sup><span class="req-star">*</span></sup>
                    </label>
                  </h6>
                </div>
                <div class="col-md-4 form-group">
                  <input type="text"
                         class="form-control" 
                         name="" 
                         id="" 
                         v-model="form.lot"> 
                </div>
              </div>
              <div class="form-row row">
                <div class="col-md-4 form-group">
                  <h6>
                    <label>
                      Objet des travaux<sup><span class="req-star">*</span></sup>
                    </label>
                  </h6>
                </div>
                <div class="col-md-4 form-group">
                  <input type="text"
                         class="form-control" 
                         name="" 
                         id="" 
                         v-model="form.objet">
                </div>
              </div>
              <div class="form-row row">
                <div class="col-md-4 form-group">
                  <h6>
                    <label for="">Référence ANO</label>
                  </h6>
                </div>
                <div class="col-md-4 form-group">
                  <input type="text"
                         class="form-control" 
                         name="" 
                         id="" 
                         v-model="form.rf_ano">
                </div>
              </div>
              <div class="form-row row">
                <div class="col-md-4 form-group">
                  <h6>
                    <label for="">Entreprise<sup><span class="req-star">*</span></sup></label>
                  </h6>
                </div>
                <div class="col-md-4 form-group">
                  <v-select
                    :options="soustraitants.donnees"
                    :reduce="soustraitant => soustraitant.id"
                    label="libelle"
                    ref="entreprise"
                    v-model="form.entreprise"
                    @input="removeSPan('entreprise')"
                    placeholder="Choisir une entreprise"
                  >
                    <span slot="no-options"
                          @click="$refs.entreprise.open = false"
                          class="text-danger">
                      Aucune Entreprise
                    </span>
                  </v-select>
                </div>
              </div>
              <div class="form-row row">
                <div class="col-md-4 form-group">
                  <h6>
                    <label for="">Références d'ordre de service de démarrage</label>
                  </h6>
                </div>
                <div class="col-md-4 form-group">
                  <input type="text"
                         class="form-control" 
                         name="" 
                         id="" 
                         v-model="form.references_ordre_service_demarrage">
                </div>
              </div>
              <div class="form-row row">
                <div class="col-md-4 form-group">
                  <h6>
                    <label for="">Date ordre de service de démarrage</label>
                  </h6>
                </div>
                <div class="col-md-4 form-group">
                  <input type="date"
                         class="form-control" 
                         name="" 
                         id="" 
                         v-model="form.date_ordre_service_demarrage">
                </div>
              </div>
              <div class="form-row row">
                <div class="col-md-4 form-group">
                  <h6>
                    <label for="">Date de signature de l'attributaire</label>
                  </h6>
                </div>
                <div class="col-md-4 form-group">
                  <input type="date"
                         class="form-control" 
                         name="" 
                         id="" 
                         v-model="form.date_sinature_attributaire">
                </div>
              </div>
              <div class="form-row row">
                <div class="col-md-4 form-group">
                  <h6>
                    <label for="">Date d'approbation {{user[1]!="DGIR"?"du marché":"de la lettre de commande"}}</label>
                  </h6>
                </div>
                <div class="col-md-4 form-group">
                  <input type="date"
                         class="form-control" 
                         name="" 
                         id="" 
                         v-model="form.date_approbation_marche">
                </div>
              </div>
              <div class="form-row row">
                <div class="col-md-4 form-group">
                  <h6>
                    <label for="">Date du démarrage effectif</label>
                  </h6>
                </div>
                <div class="col-md-4 form-group">
                  <input type="date"
                         class="form-control" 
                         name="" 
                         id="" 
                         v-model="form.date_demarrage_effectif">
                </div>

              </div>
              <div class="form-row row">
                <div class="col-md-4 form-group">
                  <h6>
                    <label for="">Date de signature de l'autorité contractante</label>
                  </h6>
                </div>
                <div class="col-md-4 form-group">
                  <input type="date"
                         class="form-control" 
                         name="" 
                         id="" 
                         v-model="form.date_signature_autorite_contractante">
                </div>
              </div>
            </div>
          </div>
        </table>

        <table class="table col-md-8 mx-auto"
               style="font-size: 0.6em;">
          <thead class="card-header"
                 id="headingFour">
            <tr class="bg-black"
                data-toggle="collapse"
                data-target="#collapseFour"
                aria-expanded="false"
                aria-controls="collapseFour">
              <th>Ordonnancement</th>
            </tr>
          </thead>
          <div id="collapseFour"
               class="collapse"
               aria-labelledby="headingFour"
               data-parent="#accordionExample">
            <div class="mt-3">
              <div class="col-md-12"
                   v-for="(item, index) in ordonnancements"
                   :key="index">
                <div class="form-row row">
                  <div class="col-md-4 form-group">
                    <h6>
                      <label
                        :for="`data[${index}]montant_ordonnancement`">Montant ordonnancement
                      </label>
                    </h6>
                  </div>
                  <div class="col-md-4 form-group">
                    <input type="text"
                           @input="addSpaceN(index)"
                           @change="verifierMontant()"
                           class="form-control"
                           v-model="item.montant_ordonnancement"
                           :name="`data[${index}]montant_ordonnancement`" />
                  </div>
                 
                </div>
                <div class="form-row row">
                  <div class="col-md-4 form-group">
                    <h6>
                      <label
                        :for="`data[${index}]annee_ordonnancement`">Année de l'ordonnancement
                      </label>
                    </h6>
                  </div>
                  <div class="col-md-4 form-group">
                    <input type="text"
                           class="form-control"
                           v-model="item.annee_ordonnancement"
                           :name="`data[${index}]annee_ordonnancement`"/>
                  </div>
                </div>
              </div>
              
              <div class="row mb-3">
                <button type="button"
                        class="btn btn-f-blue"
                        v-on:click="add_ordonnancement(),ordonnancements.push({montant_ordonnancement:'',annee_ordonnancement:''})">+</button>
                <button type="button" 
                        class="btn btn-f-blue"
                        v-on:click="ordonnancements.splice(index, 1)">-</button>
              </div>
            </div>
          </div>
        </table>
        <table class="table col-md-8 mx-auto"
               style="font-size:0.6em;">
          <thead class="card-header"
                 id="headingTwo">
            <tr class="bg-black"
                data-toggle="collapse"
                data-target="#collapseThree"
                aria-expanded="false"
                aria-controls="collapseThree">
              <th>Fichiers à télécharger</th>
            </tr>
          </thead>
          <div id="collapseThree"
               class="collapse"
               aria-labelledby="headingThree"
               data-parent="#accordionExample">
            <div class="mt-3">
              <div class="form-row row">
                <div class="col-md-4 form-group">
                  <h6>
                    <label
                    >Cautionnement AD
                    </label>
                  </h6>
                </div>
                <div class="col-md-4 form-group">
                  <div class="custom-file">
                    <label class="custom-file-label">
                      {{ inputFiles.cautionnement_ad }}
                    </label>
                    <input
                      type="file"
                      name="cautionnement_ad"
                      class="custom-file-input"
                      id="cautionnement_ad"
                      ref="cautionnement_ad"
                      placeholder="jpg jpeg png pdf xlsx csv doc"
                      accept=".jpg,.jpeg,.png,.pdf,.xlsx"
                      v-on:click="removeSPan('cautionnement_ad')"
                      v-on:change="handleFileUpload('cautionnement_ad')"
                    />
                    <span class="listExtension">
                      Format :
                      <span class="fileExtentions">{{ CreateEspaceBetweenFileExtentions() }} </span>
                    </span>
                    <span v-if="form.errors().has('cautionnement_ad') && affiche.cautionnement_ad !== 'cautionnement_ad'"
                          v-text="form.errors().get('cautionnement_ad')"
                          class="errorMsg"> </span>
                  </div>
                </div>
                <div class="form-group col-md-1 pt-2 my-auto">
                  <Loader v-show="loading.cautionnement_ad"/>
                </div>
              </div>
              <div class="form-row row">
                <div class="col-md-4 form-group">
                  <h6>
                    <label
                    >Cautionnement définitif
                    </label>
                  </h6>
                </div>
                <div class="col-md-4 form-group">
                  <div class="custom-file">
                    <label class="custom-file-label">
                      {{ inputFiles.cautionnement_definitif }}
                    </label>
                    <input
                      type="file"
                      name="cautionnement_definitif"
                      class="custom-file-input"
                      id="cautionnement_definitif"
                      ref="cautionnement_definitif"
                      placeholder="jpg jpeg png pdf xlsx csv doc"
                      accept=".jpg,.jpeg,.png,.pdf,.xlsx"
                      v-on:click="removeSPan('cautionnement_definitif')"
                      v-on:change="handleFileUpload('cautionnement_definitif')"
                    />
                    <span class="listExtension">
                      Format :
                      <span class="fileExtentions">{{ CreateEspaceBetweenFileExtentions() }} </span>
                    </span>
                    <span v-if="form.errors().has('cautionnement_definitif') && affiche.cautionnement_definitif !== 'cautionnement_definitif'"
                          v-text="form.errors().get('cautionnement_definitif')"
                          class="errorMsg"> </span>
                  </div>
                </div>
                <div class="form-group col-md-1 pt-2 my-auto">
                  <Loader v-show="loading.cautionnement_definitif"/>
                </div>
              </div>
              <div class="form-row row">
                <div class="col-md-4 form-group">
                  <h6>
                    <label
                    >Cautionnement RG
                    </label>
                  </h6>
                </div>
                <div class="col-md-4 form-group">
                  <div class="custom-file">
                    <label class="custom-file-label">
                      {{ inputFiles.cautionnement_rg }}
                    </label>
                    <input
                      type="file"
                      name="cautionnement_rg"
                      class="custom-file-input"
                      id="cautionnement_rg"
                      ref="cautionnement_rg"
                      placeholder="jpg jpeg png pdf xlsx csv doc"
                      accept=".jpg,.jpeg,.png,.pdf,.xlsx"
                      v-on:click="removeSPan('cautionnement_rg')"
                      v-on:change="handleFileUpload('cautionnement_rg')"
                    />
                    <span class="listExtension">
                      Format :
                      <span class="fileExtentions">{{ CreateEspaceBetweenFileExtentions() }} </span>
                    </span>
                    <span v-if="form.errors().has('cautionnement_rg') && affiche.cautionnement_rg !== 'cautionnement_rg'"
                          v-text="form.errors().get('cautionnement_rg')"
                          class="errorMsg"> </span>
                  </div>
                </div>
                <div class="form-group col-md-1 pt-2 my-auto">
                  <Loader v-show="loading.cautionnement_rg"/>
                </div>
              </div>
              <div class="form-row row">
                <div class="col-md-4 form-group">
                  <h6>
                    <label
                    >Référence attestation réservation de crédit
                    </label>
                  </h6>
                </div>
                <div class="col-md-4 form-group">
                  <div class="custom-file">
                    <label class="custom-file-label">
                      {{ inputFiles.reference_arc }}
                    </label>
                    <input
                      type="file"
                      name="reference_arc"
                      class="custom-file-input"
                      id="reference_arc"
                      ref="reference_arc"
                      placeholder="jpg jpeg png pdf xlsx csv doc"
                      accept=".jpg,.jpeg,.png,.pdf,.xlsx"
                      v-on:click="removeSPan('reference_arc')"
                      v-on:change="handleFileUpload('reference_arc')"
                    />
                    <span class="listExtension">
                      Format :
                      <span class="fileExtentions">{{ CreateEspaceBetweenFileExtentions() }} </span>
                    </span>
                    <span v-if="form.errors().has('reference_arc') && affiche.reference_arc !== 'reference_arc'"
                          v-text="form.errors().get('reference_arc')"
                          class="errorMsg"> </span>
                  </div>  
                </div>
                <div class="form-group col-md-1 pt-2 my-auto">
                  <Loader v-show="loading.loadrarc"/>
                </div>
              </div>
              <div class="form-row row">
                <div class="col-md-4 form-group">
                  <h6>
                    <label
                    >Ordre de service de démarrage
                    </label>
                  </h6>
                </div>
                <div class="col-md-4 form-group">
                  <div class="custom-file">
                    <label class="custom-file-label">
                      {{ inputFiles.ordre_service_demarrage }}
                    </label>
                    <input
                      type="file"
                      name="ordre_service_demarrage"
                      class="custom-file-input"
                      id="ordre_service_demarrage"
                      ref="ordre_service_demarrage"
                      placeholder="jpg jpeg png pdf xlsx csv doc"
                      accept=".jpg,.jpeg,.png,.pdf,.xlsx"
                      v-on:click="removeSPan('ordre_service_demarrage')"
                      v-on:change="handleFileUpload('ordre_service_demarrage')"
                    />
                    <span class="listExtension">
                      Format :
                      <span class="fileExtentions">{{ CreateEspaceBetweenFileExtentions() }} </span>
                    </span>
                    <span v-if="form.errors().has('ordre_service_demarrage') && affiche.ordre_service_demarrage !== 'ordre_service_demarrage'"
                          v-text="form.errors().get('ordre_service_demarrage')"
                          class="errorMsg"> </span>
                  </div>
                  <div class="form-group col-md-1 pt-2 my-auto">
                    <Loader v-show="loading.ordre_service_demarrage"/>
                  </div>
                </div>
              </div>
              <div class="form-row row">
                <div class="col-md-4 form-group">
                  <h6>
                    <label
                    >Page de garde {{user[1]!="DGIR"?"du marché":"de la lettre de commande"}}
                    </label>
                  </h6>
                </div>
                <div class="col-md-4 form-group">
                  <div class="custom-file">
                    <label class="custom-file-label">
                      {{ inputFiles.page_garde_marche }}
                    </label>
                    <input
                      type="file"
                      name="page_garde_marche"
                      class="custom-file-input"
                      id="page_garde_marche"
                      ref="page_garde_marche"
                      placeholder="jpg jpeg png pdf xlsx csv doc"
                      accept=".jpg,.jpeg,.png,.pdf,.xlsx"
                      v-on:click="removeSPan('page_garde_marche')"
                      v-on:change="handleFileUpload('page_garde_marche')"
                    />
                    <span class="listExtension">
                      Format :
                      <span class="fileExtentions">{{ CreateEspaceBetweenFileExtentions() }} </span>
                    </span>
                    <span v-if="form.errors().has('page_garde_marche') && affiche.page_garde_marche !== 'page_garde_marche'"
                          v-text="form.errors().get('page_garde_marche')"
                          class="errorMsg"> </span>
                  </div>
                </div>
                <div class="form-group col-md-1 pt-2 my-auto">
                  <Loader v-show="loading.page_garde_marche"/>
                </div>
              </div>
              <div class="form-row row">
                <div class="col-md-4 form-group">
                  <h6>
                    <label
                    >R.I.B. de la lettre de commande
                    </label>
                  </h6>
                </div>
                <div class="col-md-4 form-group">
                  <div class="custom-file">
                    <label class="custom-file-label">
                      {{ inputFiles.lettre_commande_RIB }}
                    </label>
                    <input
                      type="file"
                      name="lettre_commande_RIB"
                      class="custom-file-input"
                      id="lettre_commande_RIB"
                      ref="lettre_commande_RIB"
                      placeholder="jpg jpeg png pdf xlsx csv doc"
                      accept=".jpg,.jpeg,.png,.pdf,.xlsx"
                      v-on:click="removeSPan('lettre_commande_RIB')"
                      v-on:change="handleFileUpload('lettre_commande_RIB')"
                    />
                    <span class="listExtension">
                      Format :
                      <span class="fileExtentions">{{ CreateEspaceBetweenFileExtentions() }} </span>
                    </span>
                    <span v-if="form.errors().has('lettre_commande_RIB') && affiche.lettre_commande_RIB !== 'lettre_commande_RIB'"
                          v-text="form.errors().get('lettre_commande_RIB')"
                          class="errorMsg"> </span>
                  </div>
                </div>
                <div class="form-group col-md-1 pt-2 my-auto">
                  <Loader v-show="loading.lettre_commande_RIB"/>
                </div>
              </div>
              <div class="form-row row">
                <div class="col-md-4 form-group">
                  <h6>
                    <label
                    >Ordre de service d'arrêt
                    </label>
                  </h6>
                </div>
                <div class="col-md-4 form-group">
                  <div class="custom-file">
                    <label class="custom-file-label">
                      {{ inputFiles.ordre_service_arret }}
                    </label>
                    <input
                      type="file"
                      name="ordre_service_arret"
                      class="custom-file-input"
                      id="ordre_service_arret"
                      ref="ordre_service_arret"
                      placeholder="jpg jpeg png pdf xlsx csv doc"
                      accept=".jpg,.jpeg,.png,.pdf,.xlsx"
                      v-on:click="removeSPan('ordre_service_arret')"
                      v-on:change="handleFileUpload('ordre_service_arret')"
                    />
                    <span class="listExtension">
                      Format :
                      <span class="fileExtentions">{{ CreateEspaceBetweenFileExtentions() }} </span>
                    </span>
                    <span v-if="form.errors().has('ordre_service_arret') && affiche.ordre_service_arret !== 'ordre_service_arret'"
                          v-text="form.errors().get('ordre_service_arret')"
                          class="errorMsg"> </span>
                  </div>
                </div>
                <div class="form-group col-md-1 pt-2 my-auto">
                  <Loader v-show="loading.ordre_service_arret"/>
                </div>
              </div>
              <div class="form-row row">
                <div class="col-md-4 form-group">
                  <h6>
                    <label
                    >Ordre de service de reprise des travaux
                    </label>
                  </h6>
                </div>
                <div class="col-md-4 form-group">
                  <div class="custom-file">
                    <label class="custom-file-label">
                      {{ inputFiles.ordre_service_reprise_travaux }}
                    </label>
                    <input
                      type="file"
                      name="ordre_service_reprise_travaux"
                      class="custom-file-input"
                      id="ordre_service_reprise_travaux"
                      ref="ordre_service_reprise_travaux"
                      placeholder="jpg jpeg png pdf xlsx csv doc"
                      accept=".jpg,.jpeg,.png,.pdf,.xlsx"
                      v-on:click="removeSPan('ordre_service_reprise_travaux')"
                      v-on:change="handleFileUpload('ordre_service_reprise_travaux')"
                    />
                    <span class="listExtension">
                      Format :
                      <span class="fileExtentions">{{ CreateEspaceBetweenFileExtentions() }} </span>
                    </span>
                    <span v-if="form.errors().has('ordre_service_reprise_travaux') && affiche.ordre_service_reprise_travaux !== 'ordre_service_reprise_travaux'"
                          v-text="form.errors().get('ordre_service_reprise_travaux')"
                          class="errorMsg"> </span>
                  </div>
                </div>
                <div class="form-group col-md-1 pt-2 my-auto">
                  <Loader v-show="loading.ordre_service_reprise_travaux"/>
                </div>
              </div>
              <div class="form-row row">
                <div class="col-md-4 form-group">
                  <h6>
                    <label
                    >Avis de non Objection (ANO)
                    </label>
                        
                  </h6>
                </div>
                <div class="col-md-4 form-group">
                  <div class="custom-file">
                    <label class="custom-file-label">
                      {{ inputFiles.avis_non_objection }}
                    </label>
                    <input
                      type="file"
                      name="avis_non_objection"
                      class="custom-file-input"
                      id="avis_non_objection"
                      ref="avis_non_objection"
                      placeholder="jpg jpeg png pdf xlsx csv doc"
                      accept=".jpg,.jpeg,.png,.pdf,.xlsx"
                      v-on:click="removeSPan('avis_non_objection')"
                      v-on:change="handleFileUpload('avis_non_objection')"
                    />
                    <span class="listExtension">
                      Format :
                      <span class="fileExtentions">{{ CreateEspaceBetweenFileExtentions() }} </span>
                    </span>
                    <span v-if="form.errors().has('avis_non_objection') && affiche.avis_non_objection !== 'avis_non_objection'"
                          v-text="form.errors().get('avis_non_objection')"
                          class="errorMsg"> </span>
                  </div>
                </div>
                <div class="form-group col-md-1 pt-2 my-auto">
                  <Loader v-show="loading.avis_non_objection"/>
                </div>
              </div>
              <div class="form-row row">
                <div class="col-md-4 form-group">
                  <h6>
                    <label>Description</label>
                  </h6>
                </div>
                <div class="form-group col-md-4">
                  <div class="form-group">
                    <textarea class="form-control"
                              v-model="form.description"
                              v-on:click="removeSPan('description')"
                              rows="4"> </textarea>
                    <span v-if="form.errors().has('description') && affiche.description !== 'description'"
                          v-text="form.errors().get('description')"
                          class="errorMsg"> </span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </table>
        <div class="form-row row mt-4">
          <div class="col-md-8 offset-sm-5">
            <div class="form-group">
              <button type="button"
                      class="btn btn-f-blue"
                      @click="submit"><i class="flaticon flaticon-floppy-disk mr"></i>&nbsp; &nbsp;Enregistrer</button>

              <button type="button"
                      class="btn btn-f-blue ml-2"
                      data-toggle="modal"
                      data-target="#exampleModal"><i class="flaticon flaticon-cancel"></i> &nbsp; &nbsp;Annuler</button>
            </div>
          </div>
        </div>
      </form>
    </div>
    <!-- modal box -->
    <div class="modal fade"
         id="exampleModal"
         tabindex="-1"
         role="dialog"
         aria-labelledby="exampleModalLabel"
         aria-hidden="true">
      <div class="modal-dialog"
           role="document">
        <div class="modal-content">
          <div class="modal-header ">
            <h5 class="modal-title text-uppercase"
                id="exampleModalLabel">Annulation</h5>
            <button type="button"
                    class="close"
                    data-dismiss="modal"
                    aria-label="Close">
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div class="modal-body">
            <p>Cette action est irréversible, êtes-vous sure de vouloir annuler ?</p>
          </div>
          <div class="modal-footer">
            <button type="button"
                    class="btn btn-secondary"
                    data-dismiss="modal">Non</button>
            <button type="button"
                    @click="goBack()"
                    data-dismiss="modal"
                    class="btn btn-primary">Oui</button>
          </div>
        </div>
      </div>
    </div>
    
  </div>
</template>
<style>
.errorMsg {
  color: red;
  font-size: 10px;
  margin: 0;
  padding: 0;
}
div ::placeholder {
  color: rgba(220, 220, 220, 0.897);
}
span.displayname {
  color: #333;
  z-index: 10;
  font-size: 12px;
  padding: 10px;
  display: block;
  position: absolute;
  top: 0;
}
.listExtension {
  font-size: 0.8em;
}
.fileExtentions {
  color: #0d6efd;
} 
form input[type="text"], 
form input[type="date"], 
form input[type="number"], 
form input[type="file"], 
form textarea,
span[slot="no-options"].text-danger{
  font-size: 0.8em;
}

form select.form-control option{
  font-size: 1em;
}
form select.form-control,input.vs__search,input.vs__search:focus{
  font-size: 0.8em;
}
form input, form textarea{
  height: fit-content;
}
/* div.vs__dropdown-toggle,form select.form-control{
  height: 3.5vh;
  padding-top: 2px;
  padding-bottom: 5px;
} */
@media only screen and (width > 1466px){
  div.vs__dropdown-toggle,form select.form-control{
  height: fit-content;
  }
}
</style>
<script>
import form from "vuejs-form"
import Loader from "@/components/shared/Spinner"
import Notif from "@/components/shared/Toast"
import { mapActions, mapGetters, mapMutations } from "vuex"
import utils from "@/assets/js/utils"
import VSelect from "vue-select"
import "vue-select/dist/vue-select.css"
export default {
  name: "MrchAdd",
  components: {
    Loader,
    VSelect,
    Notif
  },
  data: () => ({
    notif: {
      activated: "",
      type: "",
      message: ""
    },
  
    ordonnancements: [
      {
        montant_ordonnancement: "",
        annee_ordonnancement: "",
       
      },
    ],
    expiration_ad:"date",
    montant_caution_ad:"",
    montant_caution_rg:"",
    montant_caution_definitif:"",
    expiration_rg:"date",
    expiration_definitif:"date",
    form: form({
      maitre_oeuvre: "",//OK
      maitrise_ouvrage_deleguee: "",//OK
      libelle: "",
      numero_marche: "",//OK
      programme: "",//OK
      rubrique: "",//OK
      activite: "",//OK
      domiciliation: "",//OK
      page_garde_marche: "",//OK
      lettre_commande_RIB: "",//OK
      ordre_service_arret:"",//OK
      date_ordre_service_arret:"",//OK
      ordre_service_demarrage:"",//OK
      date_ordre_service_demarrage:"",//OK
      ordre_service_reprise_travaux:"",//OK
      avis_non_objection:"",//OK
      departement: "",//OK
      date_debut: "",//OK
      date_fin: "",//OK
      date_fin_travaux:"",//OK
      reference_arc: "",//OK
      // reference_af: "",
      demande_information:"",
      nature_reseau:"",//OK
      type_entretien:"",//OK
      lot:"",//OK
      categorie_programme:"", 
      objet:"",//OK
      numero_marche_ou_lettre_commande:"",
      montant_initial_marche:"",//OK
      montant_caution_ad:"",
      montant_caution_rg:"",
      montant_caution_definitf:"",
      // montant_total:"",
      entreprise:"",//OK
      nom_bureau_controle:"",
      date_sinature_attributaire:"",//OK
      date_signature_autorite_contractante:"",//OK
      date_approbation_marche:"",//OK
      delai_execution_marche:"",//OK
      date_demarrage_effectif:"",//OK
      cautionnement_ad:"",//OK
      date_etablissement_cautionnement_ad:"",//OK
      expiration_caution_ad_date:"",//OK
      expiration_caution_ad_texte:"",//OK
      cautionnement_definitif:"",//OK
      date_etablissement_cautionnement_definitif:"",//OK
      expiration_caution_definitif_date:"",//OK
      expiration_caution_definitif_texte:"",//OK
      date_etablissement_cautionnement_rg:"",//OK
      cautionnement_rg:"",//OK
      expiration_caution_rg_date:"",//OK
      expiration_caution_rg_texte:"",//OK
      mission_suivi_controle:"",//OK
      description: "",
      references_ordre_service_demarrage:"",//OK
      reference_ordre_service_arret:"",//OK
      reference_ordre_service_reprise_travaux:"",//OK
      rf_ano:"",//OK
      date_reprise_travaux_selon_ordre_service:"",//OK
      ordonnancements:[],
      
      // ordonnancements:[
      //   {
      //     montant_ordonnancement:35800000,
      //     annee_ordonnancement:2023,
      //   },
      //   {
      //     montant_ordonnancement:83500000,
      //     annee_ordonnancement:2022,
      //   },]
    })
      .rules({
        numero_marche: "required",
        programme: "required",
        rubrique: "required",
        activite: "required",
        montant_initial_marche:"required", 
      })
      .messages({
        "programme.programme": "Champ requis!",
        "rubrique.rubrique": "Champ requis!",
        "activite.activite": "Champ requis!",
        "montant_initial_marche.montant_initial_marche":"Champ requis!", 
      }),
    verifMontant:false,
    countval: 0,
    uploadfile: "",
    rubriquesList: [],
    programmeList: [],
    activitesList: [],
    banks:[],
    is_inferieur:true,
    montant_definitif_activite:0,
    formData: {
      limite: 100,
      apres: "MQ=="
    },
    loading:{
      ordre_service_arret:false,
      ordre_service_demarrage:false,
      ordre_service_reprise_travaux:false,
      avis_non_objection:false, 
      cautionnement_ad:false,
      cautionnement_rg:false,
      cautionnement_definitif:false,
      page_garde_marche:false,
      lettre_commande_RIB:false,
      loadrarc:false
    },
    // loadrarc: false,
    // loadraf: false,
    montant_initial_marche:"",
    montant_ordonnancement:"",
    // cout_marche:"",
    reps: false,
    inputFiles: { 
      ordre_service_arret:"",
      ordre_service_demarrage:"",
      ordre_service_reprise_travaux:"",
      avis_non_objection:"",
      reference_arc: "", 
      reference_af: "", 
      cautionnement_ad: "", 
      cautionnement_definitif: "",
      cautionnement_rg: "",
      page_garde_marche: "", 
      lettre_commande_RIB: "" 
    },
    affiche: {
      maitre_oeuvre: "maitre_oeuvre:true",
      maitrise_ouvrage_deleguee: "maitrise_ouvrage_deleguee:true",
      domiciliation: "domiciliation:true",
      libelle: "libelle:true",
      numero_marche: "numero_marche:true",
      programme: "programme:true",
      rubrique: "rubrique:true",
      activite: "activite:true",
      cout_marche: "cout_marche:true",
      departement: "departement:true",
      date_debut: "date_debut:true",
      date_fin: "date_fin:true",
      reference_arc: "reference_arc:true",
      page_garde_marche: "page_garde_marche:true",
      lettre_commande_RIB: "lettre_commande_RIB:true",
      description: "description:true"
    },
    listExtensionFiles: [],
    gestion_date_debut: "date_debut",
    gestion_date_fin: "date_fin",
    user:""
  }),
  watch: {
    ["form.data"]: {
      deep: true,
      immediate: false,
      handler: "onFormChange"
    },
    singleMarches() {
      if (this.singleMarches) {
        this.notif.message = this.singleMarches
        this.notif.type = "toast-success"
        this.notif.activated = "activated"
        setTimeout(
          function() {
            this.notif.activated = ""
            this.singleMarche("")
            this.$router.push({ name: "Marche" })
          }.bind(this),
          3000
        )
      }
    },
    marcherrors() {
      if (this.marcherrors) {
        this.notif.message = this.marcherrors
        this.notif.type = "toast-danger"
        this.notif.activated = "activated"
        setTimeout(
          function() {
            this.notif.activated = ""
            this.setErors("")
          }.bind(this),
          3000
        )
      }
    },
    // banques(){
    //   if (this.banques.donnees) {
    //     this.banks=[]
    //     this.banques.donnees.forEach(banque => {
    //       if (banque.compte_bancaires.length>0) {
    //         this.banks.push(banque)
    //       }
    //     })
    //     // console.log(this.banks)
    //   }
    // },
    banques(){
      if (this.banques.donnees) {
        this.banks=[]
        this.banques.donnees.forEach(banque => {
          if (banque.compte_bancaires.length>0) {
            banque.compte_bancaires.forEach(compte => {
              this.banks.push({id:compte.id,numero:compte.numero+"("+banque.sigle+")"})
            })
          }
        })
        // console.log(this.banks)
      }
    },
    files() {
      if (this.files && this.files["url"]) {
        this.form[this.uploadfile] = process.env.VUE_APP_UPLOAD + this.files["url"]
        this.loading[this.uploadfile] = false
        this.$refs["reference_arc"].disabled = false
      }
    },

    filerrors() {
      if (this.filerrors) {
        this.loadrarc = false
        this.notif.message = this.filerrors
        this.notif.type = "toast-danger"
        this.notif.activated = "activated"
        setTimeout(
          function() {
            this.notif.activated = ""
            this.setErors("")
          }.bind(this),
          3000
        )
        this.$refs["reference_arc"].disabled = false
      }
    },
    programmes() {
      this.programmeList = this.programmes
      this.listExtensionFiles = utils.formaFichierAccepte
    },
    assignProgramme() {
      this.rubriquesList = []
      this.rubriquesList = this.assignProgramme.programme.rubriques
    },

  },
  computed: {
    ...mapGetters(["marcherrors", "singleMarches", "departements", "programmes", "prestataires", "files", "filerrors", "soustraitants", "missionControls", "assignProgramme","banques"])
  },
  mounted() {
    var filtre=[{libelle:"categorie_programme",table:"programmes",nature:"egale",valeur:"programme_entretien_routier",type:"caractere"}]
    localStorage.setItem("module","marches")
    const userdetails = JSON.parse(localStorage.getItem("user"))
    this.user = userdetails.user_role.split("_")
    console.log(this.user)
    if (this.user[1]=="AGEROUTE") {
      this.form.maitre_oeuvre=1
    }else{
      if (this.user[1]=="DGIR") {
        this.form.maitre_oeuvre=2
      }  
    }
    this.ListProgrammes(filtre)
    this.loadBanques()
    this.getDepartements()
    this.getPrestataires()
    this.listSousTraitants()
    this.getMissionControls()
  },
  methods: {
    ...mapActions(["saveMarches", "listSousTraitants", "getDepartements", "ListProgrammes", "getPrestataires", "saveFile", "getMissionControls", "getProgramme","loadBanques",]),
    ...mapMutations(["singleMarche", "setErors"]),
    onFormChange() {
      //this.form.validate()
    },
    // recreate(value){
    //   var splinter = value.split(" ")
    //   this.form.cout_marche = ""
    //   // console.log(splinter)
    //   for (let index = 0; index < splinter.length; index++) {
    //     this.form.cout_marche += splinter[index]
    //   }
    // },
    add_ordonnancement(){
      console.log(this.ordonnancements)
    },
    submit() {
      //appel du tableau ordonnancement
      if(this.ordonnancements.length == 1 && this.ordonnancements[0].annee_ordonnancement== "" ){
        this.ordonnancements[0].montant_ordonnancement = this.form.montant_initial_marche
        this.ordonnancements[0].annee_ordonnancement = this.form.date_debut.split("-")
        this.ordonnancements[0].annee_ordonnancement = this.ordonnancements[0].annee_ordonnancement[0]
      }
      if(!this.verifMontant){
        this.form.ordonnancements=this.ordonnancements
        this.form.validate()
        // this.recreate(this.form.cout_marche)
        console.log(this.form.data)
        if (this.is_inferieur) {
          if (
            !this.form
              .validate()
              .errors()
              .any()
          ) {
            this.saveMarches(this.form.data)
          } else {
            for (let afficheKey in this.affiche) {
              const tabVale = this.affiche[afficheKey].split(":")
              if (tabVale.length === 1) {
                this.affiche[afficheKey] = tabVale[0] + ":true"
              }
            }
          }
        }else{
          this.notif.message = "Le montant du marché ne doit pas excéder le montant de l'activité"
          this.notif.type = "toast-danger"
          this.notif.activated = "activated"
          setTimeout(
            function() {
              this.notif.activated = ""
            }.bind(this),
            3000
          )
        }
      }else{
        this.notif.message = "le montant total des ordonnancements ne doit pas dépasser celui du marché"
        this.notif.type = "toast-danger"
        this.notif.activated = "activated"
        this.loadrarc = false

        setTimeout(
          function() {
            this.notif.activated = ""
          }.bind(this),
          3000
        )
      }
      
    },
    checkExpiration(key,formKey){
      console.log(this[key])
      if (this[key]!="date") {
        this.form[formKey]=this[key]
      }
    },
    handleFileUpload(filename) {
      // initialisation de la variable de vérification du nom
      var oldName = this.inputFiles[filename]
      if (this.$refs[filename].files[0]) {
        //condition d'existance d'un nouveau fichier
        if (this.$refs[filename].files[0].name != oldName) {
          // vérification de la concordance des noms
          this.loading[filename] = true
        }
        this.inputFiles[filename] = this.$refs[filename].files[0].name
        if (!this.inputFiles[filename].includes(" ")) {
          var extension = this.inputFiles[filename].split(".")[1]
          if (this.listExtensionFiles.includes(extension)) {
            this.uploadfile = filename
            this.saveFile(this.$refs[filename].files[0])
          } else {
            this.loading[filename] = false
            this.$refs["reference_af"].disabled = false
            this.$refs["reference_arc"].disabled = false
            this.inputFiles[filename] = ""

            this.notif.message = "Mauvais Format du fichier"
            this.notif.type = "toast-danger"
            this.notif.activated = "activated"
            setTimeout(
              function() {
                this.notif.activated = ""
                this.setErors("")
              }.bind(this),
              5000
            )
          }  
        }else{
          this.notif.message = "Vérifiez qu'il n'y a pas d'espaces dans le nom du fichier"
          this.notif.type = "toast-danger"
          this.notif.activated = "activated"
          this.loadrarc = false

          setTimeout(
            function() {
              this.notif.activated = ""
              this.$refs[filename].value=""
              this.inputFiles[filename]=""
            }.bind(this),
            3000
          )
        }
        
      }
    },
    onRubriques(evt) {
      if (this.rubriquesList) {
        var app = this
        this.rubriquesList.filter(function(proArr) {
          if (proArr.id == evt.target.value) {
            app.activitesList = proArr.activites
          }
        })
      }
    },
    monthDiff() {
      var debut = this.form.date_debut.split("-"),
          fin = this.form.date_fin.split("-")
      
      if ((this.form.date_debut!="" && this.form.date_debut!="0000-00-00") && (this.form.date_fin!="" && this.form.date_fin!="0000-00-00") ) {
        debut = new Date(debut[0],debut[1],debut[2]) 
        fin = new Date(fin[0],fin[1],fin[2]) 
        var months
        months = (fin.getFullYear() - debut.getFullYear()) * 12
        months -= debut.getMonth()
        months += fin.getMonth()
        this.form.delai_execution_marche = months
      }  
    },
    /**
     * Permet de faire une navigation en retour
     */
    goBack() {
      this.$router.push({ name: "Marche" })
    },
    /**
     * Cette fonction permet de retirer les messges lorsque le formulaire n'est pas complété
     * @param element l'element pour lequel le message est retirer
     */
    removeSPan(element) {
      for (let afficheKey in this.affiche) {
        const valeur = this.affiche[afficheKey].split(":")[0]
        if (valeur === element) {
          this.affiche[afficheKey] = valeur
        }
      }
    },
    addSpace(key) {
      var chaine = ""
      var splinter = this[key].split("")
      var res =""
      this.form[key]=""
      if (/[0-9]/.test(this[key]) || !/[A-Za-zéèàôêâîûöïüëä$&+,:;=?@#|"~{}'<>.-^*()%!]/.test(this[key])) {
        for (let index = 0; index < splinter.length; index++) {
          if (!/[0-9]/.test(splinter[index]) && !splinter[index] == " ") {
            splinter[index] = ""
          }
          chaine += splinter[index]
        }
        if (this[key].length >= 4) {
          // check if there's a space
          if (this[key].includes(" ")) {
            var trimer = chaine.split(" ")
            chaine = ""
            for (let index = 0; index < trimer.length; index++) {
              chaine += trimer[index]
            }
            chaine = chaine.replace(/\B(?=(\d{3})+(?!\d))/g, " ")
          } else {
            chaine = ""
            chaine = this[key].replace(/\B(?=(\d{3})+(?!\d))/g, " ")
          }
        }
      }
      this[key] = chaine
      res = this[key].split(" ")
      for (let index = 0; index < res.length; index++) {
        this.form[key] += res[index]
      }
      this.form[key]=Number(this.form[key])
    },
    
    addSpaceN(key) {
      if (this.ordonnancements[key]) {
        var chaine = ""
        var splinter = this.ordonnancements[key].montant_ordonnancement.split("")
        this.ordonnancements[key].montant_ordonnancement = ""

        if (/[0-9]/.test(this.ordonnancements[key].montant_ordonnancement) || !/[A-Za-zéèàôêâîûöïüëä$&+,:;=?@#|"~{}'<>.-^*()%!]/.test(this.ordonnancements[key].montant_ordonnancement)) {
          for (let index = 0; index < splinter.length; index++) {
            if (!/[0-9]/.test(splinter[index]) && !splinter[index] == " ") {
              splinter[index] = ""
            }
            chaine += splinter[index]
          }
          if (this.ordonnancements[key].montant_ordonnancement.length >= 4) {
            // check if there's a space
            if (this.ordonnancements[key].montant_ordonnancement.includes(" ")) {
              var trimer = chaine.split(" ")
              chaine = ""
              for (let index = 0; index < trimer.length; index++) {
                chaine += trimer[index]
              }
              chaine = chaine.replace(/\B(?=(\d{3})+(?!\d))/g, " ")
            } else {
              chaine = ""
              chaine = this.ordonnancements[key].montant_ordonnancement.replace(/\B(?=(\d{3})+(?!\d))/g, " ")
            }
          }
        }
        this.ordonnancements[key].montant_ordonnancement = chaine
      }
    },
    verifierMontant(){
      console.log(this.form.montant_initial_marche)
      var total=0
      this.verifMontant=false
      this.ordonnancements.forEach(ordonnancement => {
        console.log(ordonnancement.montant_ordonnancement)
        total += Number(ordonnancement.montant_ordonnancement)  
      })
      console.log(total)
      if (total > this.form.montant_initial_marche) {
        this.verifMontant=true
        this.notif.message = "le montant total des ordonnancements ne doit pas dépasser celui du marché"
        this.notif.type = "toast-danger"
        this.notif.activated = "activated"
        this.loadrarc = false

        setTimeout(
          function() {
            this.notif.activated = ""
          }.bind(this),
          3000
        )
      }else{
        this.verifMontant=false
      }
    },
    getMontantActivite(id){
      this.activitesList.forEach(activite => {
        if(activite.id == id){
          this.montant_definitif_activite=activite.montant_definitif.toLocaleString()
        }
      })
    },
    checkMontantActivite(id){
      this.activitesList.forEach(activite => {
        if((activite.id == id) && (activite.montant_definitif < this.form.montant_initial_marche)){
          this.is_inferieur=false
        }else{
          this.is_inferieur=true
        }
      })
    },
    /**
     * voir dans src/assets/js/utils
     */
    getDate() {
      utils.getDate(this, this.gestion_date_debut, this.gestion_date_fin, "marche")
    },
    /**
     * La fonction return la le programme dont l'id à été selectionné afin d'alimenter la liste des rubriques
     * @param value l'id du programme
     */
    getProgrameIdSelected(value) {
      this.getProgramme(value)
      this.removeSPan("programme")
    },
    /**
     * Recupére l'id de la rubrique selectionné et alimente la liste des activités de cette rubrique
     * @param value l'id de la rubrique
     */
    getRubriqueIdSelected(value) {
      if (this.rubriquesList) {
        const app = this
        this.rubriquesList.filter(function(proArr) {
          if (proArr.id == value) {
            app.activitesList = proArr.activites
          }
        })
      }
      this.removeSPan("rubrique")
    },
    /**
     * Creation des espaces pour les extentions de fichiers
     * @returns {string}
     * @constructor
     */
    CreateEspaceBetweenFileExtentions() {
      return utils.formaFichierAccepteToString()
    },
    selectProgramme(){
      var filtre=""
      if (this.form.categorie_programme == "programme_investissement") {
        filtre=[{libelle:"categorie_programme",table:"programmes",nature:"egale",valeur:"programme_investissement",type:"caractere"}]
        this.ListProgrammes(filtre)
      }else{
        filtre=[{libelle:"categorie_programme",table:"programmes",nature:"egale",valeur:"programme_entretien_routier",type:"caractere"}]
        this.ListProgrammes(filtre)
      }

    },
  }
}
</script>
